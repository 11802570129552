"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {
    ChangePassword: 'Change Password',
    Dashboard: 'Dashboard',
    Report: 'Report',
    Reports: 'Reports',
    LabourCost: 'Labour Cost',
    StaffAttendance: 'Staff Attendance',
    Productivity: 'Productivity',
    OverStoragePeriod: 'Storage Period',
    UserManagement: 'User Management',
    POS: 'POS',
    COGS: 'COGS',
    Modifier: 'Modifier',
    Item: 'Item',
    Product: 'Product',
    ProductType: 'Product Type',
    SubProductType: 'Sub Product Type',
    StoreProduct: 'Store Product',
    AddStoreProduct: 'Add Store Product',
    EditStoreProduct: 'Edit Store Product',
    StoreProductCombo: 'Store Product Combo',
    AddStoreProductCombo: 'Add Store Product Combo',
    EditStoreProductCombo: 'Edit Store Product Combo',
    StoreExpense: 'Store Expense',
    AddStoreExpense: 'Add Store Expense',
    EditStoreExpense: 'Edit Store Expense',
    Category: 'Category',
    Kitchen: 'Kitchen Station',
    Users: 'Staff',
    EditUser: 'Edit User',
    AddUser: 'Add User',
    MOOMOOMemberManagement: 'MOOMOO Member Management',
    MOOMOOMembers: 'MOOMOO Members',
    ShowMOOMOOMember: 'Show MOOMOO Member',
    EditMOOMOOMember: 'Edit MOOMOO Member',
    AddMOOMOOMember: 'Add MOOMOO Member',
    DeliveryTypeManagement: 'Delivery Type Management',
    DeliveryTypes: 'Delivery Type',
    ShowDeliveryTypes: 'Show Delivery Type',
    AddDeliveryTypes: 'Add Delivery Type',
    EditDeliveryTypes: 'Edit Delivery Type',
    StoreManagement: 'Store Management',
    Stores: 'Stores',
    EditStore: 'Edit Store',
    Sale: 'Sale',
    SaleUpload: 'Sale Upload',
    SalaryReport: 'Salary Report',
    AddStore: 'Add Store',
    Roster: 'Roster',
    AttendanceManagement: 'Attendance Management',
    Attendances: 'Attendances',
    AddAttendance: 'Add Attendance',
    Leaves: 'Leaves',
    LeaveType: 'Leave Type',
    LeaveSummary: 'Leave Summary',
    LeaveAdd: 'Leave Add',
    LeaveEdit: 'Leave Edit',
    LeaveHistory: 'Leave History',
    RoleMapping: 'Role Mapping',
    LeaveApplication: 'Leave Application',
    LeaveApproval: 'Leave Approval',
    LeaveSetting: 'Leave Setting',
    PurchaseOrderHistory: 'Purchase Order History',
    Payroll: 'Payroll',
    Shifts: 'Shifts',
    EditShift: 'Edit Shift',
    AddShift: 'Add Shift',
    Expenses: 'Expenses',
    EditExpense: 'Edit Expense',
    AddSExpense: 'Add Expense',
    ExpenseClaims: 'Expense Claims',
    ExpenseClaimList: 'Expense Claim List',
    AddExpenseClaim: 'Add Expense Claim',
    EditExpenseClaim: 'Edit Expense Claim',
    Message: 'Message',
    EditMessage: 'Edit Message',
    AddMessage: 'Add Message',
    MaterialPlanning: 'Procurement',
    Supplier: 'Supplier',
    EditSupplier: 'Edit Supplier',
    AddSupplier: 'Add Supplier',
    RawMaterial: 'Raw Material',
    EditRawMaterial: 'Edit Raw Material',
    PrintRawMaterialPacking: 'Print Raw Material Packing',
    AddRawMaterial: 'Add Raw Material',
    RawMaterialScheduledPrice: 'Raw Material Scheduled Price',
    AddRawMaterialScheduledPrice: 'Add Raw Material Scheduled Price',
    PurchaseRequests: 'Purchase Requests',
    EditPurchaseRequest: 'Edit Purchase Request',
    AddPurchaseRequest: 'Add Purchase Request',
    PurchaseOrders: 'Purchase Orders',
    PurchaseOrdersRequest: 'Purchasing',
    EditPurchaseOrder: 'Edit Purchase Order',
    AddPurchaseOrder: 'Add Purchase Order',
    GoodReceived: 'Good Received',
    EditGoodReceived: 'Edit Good Received',
    AddGoodReceived: 'Add Good Received',
    WarehouseManagement: 'Warehouse',
    Warehouses: 'Inventory',
    AddWarehouse: 'Add Warehouse',
    EditWarehouseArea: 'Edit Warehouse Area',
    WarehouseArea: 'Warehouse Area',
    WarehouseAreas: 'Warehouse Areas',
    WarehouseAreasRawMaterial: 'Warehouse Area Raw Material',
    StockIn: 'Stock In',
    StockOut: 'Stock Out',
    StockTake: 'Stock Take',
    StockTransit: 'Stock Transit',
    WarehouseRecords: 'Warehouse Records',
    WarehouseInventoryLv: 'Inventory Lv',
    List: 'List',
    Order: 'Self Order Sys.',
    Orders: 'Order',
    EditOrder: 'Edit Order',
    SummaryOrder: 'Summary',
    Labour: 'Human Resources',
    CRM: 'CRM',
    Schedule: 'Stores & Schedule',
    CashExpense: 'Cash Expense',
    CashExpenseEdit: 'Edit Cash Expense',
    CashExpenseAdd: 'Add Cash Expense',
    CashExpenseList: 'Cash Expense List',
    CashExpenseReport: 'Cash Expense Report',
    UserOTRecord: 'User OT Record'
  },
  Supplier: {
    AddSupplier: 'Add Supplier',
    Name: 'Name',
    ShortName: 'Short Name',
    Actions: 'Actions',
    Edit: 'Edit',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Recorddeleted: 'Record deleted',
    ContactName: 'Contact Name',
    Tel: 'Tel',
    Email: 'Email',
    Address: 'Address',
    Remark: 'Remark',
    RequiredNameMessage: 'Name is required',
    IncorrectEmailMessage: 'Please input correct email address',
    UpdateFailedMessage: 'Update failed, please retry.',
    Add: 'Add',
    Supplierupdated: 'Supplier updated',
    Suppliercreated: 'Supplier created'
  },
  Warehouse: {
    AddWarehouse: 'Add Warehouse',
    Name: 'Name',
    Actions: 'Actions',
    Action: 'Action',
    Delete: 'Delete',
    ConfirmDeleteMessage: 'Confirm Delete Message',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    Recorddeleted: 'Record deleted',
    Area: 'Area',
    Areas: 'Areas',
    AddWarehouseArea: 'Add Warehouse Area',
    AddWarehouseAreaRawMaterial: 'Add Raw Material',
    SKU: 'SKU',
    QTY: 'QTY',
    Back: 'Back',
    StockIn: 'Stock In',
    StockOut: 'Stock Out',
    History: 'History',
    InventoryLv: 'Inventory Lv',
    DownloadQR: 'Download QR',
    totalStock: 'Total Stock',
    availableStock: 'Available Stock',
    HiddenWarehouse: 'Inventory Warehouse',
    Yes: 'Yes',
    No: 'No'
  },
  WarehouseArea: {
    Name: 'Name',
    Add: 'Add',
    Edit: 'Edit',
    Submit: 'Submit',
    Recorddeleted: 'Record deleted',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Warehouse: 'Warehouse',
    WarehouseAreacreated: 'Warehouse Area Created',
    WarehouseAreaupdated: 'Warehouse Area Updated',
    UpdateFailedMessage: 'Update Failed',
    RawMaterial: 'Raw Material',
    History: 'History'
  },
  RawMaterialScheduledPrice: {
    AddRawMaterialScheduledPrice: 'Add Raw Material Scheduled Price',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    Price: 'Price',
    Actions: 'Actions',
    Action: 'Action',
    Add: 'Add',
    Edit: 'Edit',
    Submit: 'Submit',
    Recorddeleted: 'Record deleted',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Back: 'Back',
    UpdateFailedMessage: 'Update failed, please retry.',
    RequiredMessage: 'Required',
    UpdatedAt: 'Updated At',
    CreatedBy: 'Created By'
  },
  WarehouseAreaRawMaterial: {
    Name: 'Name',
    Add: 'Add',
    Edit: 'Edit',
    Submit: 'Submit',
    Recorddeleted: 'Record deleted',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Warehouse: 'Warehouse',
    WarehouseArea: 'Warehouse Area',
    WarehouseAreaRawMaterialcreated: 'Warehouse Area Created',
    WarehouseAreaRawMaterialupdated: 'Warehouse Area Updated',
    UpdateFailedMessage: 'Update Failed',
    RawMaterial: 'Raw Material',
    StockIn: 'Stock In',
    StockOut: 'Stock Out',
    StockTake: 'Stock Take',
    StockTransit: 'Stock Transit',
    History: 'History'
  },
  WarehouseRecord: {
    Warehouse: 'Warehouse',
    WarehouseArea: 'Warehouse Area',
    ToWarehouse: 'To Warehouse',
    ToWarehouseArea: 'To Warehouse Area',
    RawMaterial: 'Raw Material',
    Name: 'Name',
    Actions: 'Actions',
    QTY: 'QTY',
    MainQty: 'Main Unit QTY',
    SubQty: 'Sub Unit QTY',
    Remark: 'Remark',
    Action: 'Action',
    Add: 'Add',
    Edit: 'Edit',
    Submit: 'Submit',
    Recorddeleted: 'Record deleted',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Back: 'Back',
    WarehouseRecordcreated: 'Warehouse Record Created',
    UpdateFailedMessage: 'Update failed, please retry.',
    RequiredMessage: 'Required',
    UpdatedBy: 'Updated By',
    UpdatedAt: 'Updated At'
  },
  GoodReceivedNotes: {
    All: 'All',
    WarehouseArea: 'Warehouse Area',
    AddGoodReceivedNotes: 'Add Good Received Note',
    Confirm: 'Confirm',
    POOutstanding: 'Outstanding',
    Confirmed: 'Confirmed',
    Completed: 'Compeleted',
    Drafted: 'Drafted',
    Date: 'Date',
    Processing: 'Processing',
    Items: 'Items',
    ItemAdd: 'Add Item',
    Warehouse: 'Warehouse',
    RequestQty: 'Requested Qty',
    Result: 'Result',
    RawMaterial: 'Raw Material',
    OutstandingQty: 'Outstanding Qty',
    Qty: 'Qty',
    ReceivedQty: 'Received Qty',
    Operations: 'Operations',
    Remark: 'Remark',
    Name: 'Name',
    Keyword: 'Keyword',
    Action: 'Action',
    Search: 'Search',
    Select: 'Select',
    Deselect: 'Deselect',
    Selected: 'Selected',
    Suppplier: 'Supplier',
    DownloadTransitNote: 'Download Transit Note',
    POQty: 'PO Qty ',
    POReceived: 'PO Received',
    POOutstaing: 'PO Outstanding',
    Received: 'Received',
    Allocated: 'Allocated',
    PendingAllocation: 'Pending Allocation',
    BackToList: 'Back to List',
    Copy: 'Copy',
    Status: 'Status',
    UpdatedAt: 'Updated At',
    PONumber: 'PO Number',
    GoodReceivedDate: 'Good Received Date',
    Save: 'Save',
    Submit: 'Submit',
    Delete: 'Delete',
    Unsubmit: 'Unsubmit',
    Reject: 'Reject',
    Complete: 'Complete',
    DownloadGRN: 'Download Good Received Note',
    DownloadTN: 'Download Transit Note',
    PurchaseRequestUpdated: 'Purchase Request Updated',
    PurchaseRequestCreated: 'Purchase Request Created',
    UpdateFailed: 'Update Failed, please retry',
    CreateFailed: 'Create Failed, please retry',
    DeleteFailed: 'Delete Failed, please retry',
    RequestedQty: 'Requested Qty',
    BackToPO: 'Back To PO',
    ConfirmRequest: 'Confirm to Confirm Request?',
    ConfirmRequestFailed: 'Confirm Request Failed, please retry',
    CompleteRequestFailed: 'Complete Request Failed, please Retry',
    ConfirmDelete: 'Confirm to Delete Record ?',
    ConfirmRequrest: 'Confirm to Confirm Request ?',
    Deleted: 'Record Deleted',
    RequestConfirmed: 'Request Confirmed',
    RequestCompleted: 'Request Completed',
    InvalidInput: 'Invalid Input',
    RequiredMessage: 'Required',
    Type: 'Type'
  },
  PurchaseOrders: {
    Requested: 'Requested',
    PurchaeOrdersText: 'Purchase Orders Text',
    Search: 'Search',
    RawMaterial: 'Raw Material',
    Processing: 'Processing',
    Submitted: 'Submitted',
    Confirmed: 'Confirmed',
    Outstanding: 'Outstanding',
    Completed: 'Completed',
    Action: 'Action',
    ShareWhatsApp: 'Share on Whatsapp',
    Select: 'Select',
    Deselect: 'Deselect',
    Selected: 'Selected',
    KeyWord: 'KeyWord',
    Status: 'Status',
    Result: 'Result',
    PODate: 'PO Date',
    DelivDate: 'Delivery Date',
    DelivAddress: 'Delivery Address',
    ContactName: 'Contact Name',
    ContactTel: 'Contact Tel',
    ContactEmail: 'Contact Email',
    ContactAddress: 'Contact Address',
    SuppContactName: 'Supplier Contact Name',
    SuppContactTel: 'Supplier Contact Tel',
    SuppContactEmail: 'Supplier Contact Email',
    PurchaseRequestItem: 'Purchase Request Item',
    AddItem: 'Add Item',
    WareHouse: 'WareHouse',
    ReqQty: 'Requested Qty',
    Name: 'Name',
    Qty: 'Qty',
    UnitPrice: 'Unit Price',
    Subtotal: 'Subtotal',
    Remark: 'Remark',
    Operations: 'Operations',
    ReceiveRemark: 'Receive Remark',
    RelatedPR: 'Related PR',
    RelatedGR: 'Related Good Receive',
    DownloadPO: 'Download PO',
    Copy: 'Copy',
    BackToList: 'Back to List',
    PRUpdated: 'Purchase Request Updated',
    PRCreated: 'Purchase Request Created',
    POUpdated: 'Purchase Order Updated',
    POCreated: 'Purchase Order Created',
    UpdateFailed: 'Update Failed, please retry',
    CreateFailed: 'Create Failed, please retry',
    DeleteFailed: 'Delete Failed, please retry',
    ConfirmFailed: 'Confirm Failed, please retry',
    ConfirmDelete: 'Confirm to Delete Record?',
    ConfirmRequest: 'Confirm to Confirm Request?',
    ConfirmGenerateGR: 'Confirm to generate Good Received Note?',
    GRFailed: 'Generate Good Received Note Failed, please retry',
    DownloadFailed: 'Download Failed, please retry',
    ReqConfirmed: 'Request Confirmed',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Deleted: 'Record Deleted',
    Save: 'Save',
    Confirm: 'Confirm',
    buttonSubmit: 'Submit',
    buttonDelete: 'Delete',
    buttonUnsubmit: 'Unsubmit',
    buttonConfirm: 'Confirm',
    buttonReject: 'Reject',
    buttonComplete: 'Complete',
    buttonDownload: 'Download',
    buttonGenerateGR: 'Generate Good Received',
    GRGenerated: 'Good Received Note Generated',
    RequiredMessage: 'Required',
    GCGenerated: 'Good Received Note Canceled',
    buttonGenerateGC: 'Cancel Amount',
    GCFailed: 'Cancel Amount Failed, please retry'
  },
  PurchaseRequests: {
    Unsubmit: 'Unsubmit',
    GeneratePO: 'Generate PO',
    GeneratePOByStore: 'Generate PO By Store',
    Category: 'Category',
    Items: 'Items',
    TargetRestockDate: 'Target Restock',
    Status: 'Status',
    UpdatedAt: 'Updated At',
    PurchaseOrders: 'PurchaseOrders',
    Store: 'Store',
    Search: 'Search',
    ConfirmToGeneratePO: 'Confirm to Generate PO?',
    ConfirmToGeneratePOByStore: 'Confirm to Generate PO By Store?',
    Name: 'Name',
    Actions: 'Actions',
    QTY: 'QTY',
    Action: 'Action',
    Add: 'Add',
    Edit: 'Edit',
    Show: 'Show',
    Cancel: 'Cancel',
    POGenerated: 'PO Generated ',
    CantGeneratePOGenerated: 'Cannot generate PO: ',
    RecordDeleted: 'Record Deleted',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Reject',
    Filter: 'Filter',
    WarehouseRecordcreated: 'Warehouse Record Created',
    UpdateFailedMessage: 'Update failed, please retry.',
    RequiredMessage: 'Required',
    SubTotal: 'Sub Total',
    UnitPrice: 'Unit Price',
    Qty: 'Qty',
    Item: 'Item',
    Stores: 'Stores',
    PickStores: 'Pick Stores',
    PickDate: 'Pick Date',
    All: 'All',
    Submitted: 'Submitted',
    Confirmed: 'Confirmed',
    Outstanding: 'Outstanding',
    Drafted: 'Drafted',
    PendingApproval: 'Pending Approval',
    Processing: 'Processing',
    Completed: 'Completed',
    PurchaseRequestItem: 'Purchase Request Item',
    Operations: 'Operations',
    Remark: 'Remark',
    RelatedPO: 'Related PO',
    AddItem: 'Add Item',
    Fullfilled: 'Fullfilled',
    Back: 'Back',
    Save: 'Save',
    Submit: 'Submit',
    UnSubmit: 'Unsubmit',
    Reject: 'Reject',
    Complete: 'Complete',
    Download: 'Download',
    Generate: 'Generate',
    GeeneratePO: 'Generate PO',
    UpdatedPurchaseRequests: 'Purchase Request Updated',
    UpdateFailed: 'Update Failed, please retry',
    CreatedPurchaseRequests: 'Purchase Request created',
    CreateFailed: 'Create Failed, please retry',
    ConfirmDelete: 'COnfirm to delete record?',
    DeleteFailed: 'Delete Request Failed, please retry. ',
    ConfirmSubmitRequest: 'Confirm to submit request?',
    ConfirmUnsubmitRequest: 'Confirm to unsubmit request? ',
    ConfirmPurchaseRequest: 'Confirm to confirm Request?',
    ConfirmRequestFailed: 'Confirm Request Failed, please retry',
    ConfirmRejectRequest: 'Confirm to reject request?',
    RequestSubmitted: 'Request Submitted',
    RequestRejected: 'Request Rejected',
    SubmitRequestFailed: 'Submit Request Failed, please retry.',
    RequestConfirmed: 'Request Confirmed',
    RejectRequestFailed: 'Reject Request Failed, please retry',
    ConfirmCompleteRequest: 'Conform to complete  request?',
    RequestCompleted: 'Request Completed',
    CompleteRequestFailed: 'Compelete Request Failed, please retry',
    ConfirmGeneratePO: 'Confirm to Generate PO?',
    GeneratePOFailed: 'Failed Generate PO, please retry',
    DownloadFailed: 'Download Failed, please retry',
    ChooseRawMaterial: 'Choose Raw Material',
    KeyWord: 'KeyWord',
    Result: 'Result',
    RawMaterial: 'Raw Material'
  },
  Category: {
    LoadFail: 'Load Fail',
    SubmitFail: 'Submit Fail',
    Created: 'Category created',
    Updated: 'Category updated',
    Deleted: 'Category deleted'
  },
  ProductType: {
    Add: 'Add Product Type',
    Edit: 'Edit Product Type',
    Created: 'Product Type created',
    Updated: 'Product Type updated',
    Deleted: 'Product Type deleted'
  },
  SubProductType: {
    Add: 'Add Sub Product Type',
    Edit: 'Edit Sub Product Type',
    Created: 'Sub Product Type created',
    Updated: 'Sub Product Type updated',
    Deleted: 'Sub Product Type deleted'
  },
  Modifier: {
    Add: 'Add Modifier',
    Edit: 'Edit Modifier',
    Created: 'Modifier created',
    Updated: 'Modifier updated',
    Deleted: 'Modifier deleted'
  },
  Item: {
    Add: 'Add Item',
    Edit: 'Edit Item',
    Created: 'Item created',
    Updated: 'Item updated',
    Deleted: 'Item deleted',
    PresetSaved: 'Preset Saved',
    LoadSuccess: 'Load Success',
    ImportSuccess: 'Import Success'
  },
  Order: {
    emptyText: 'No Data',
    Name: 'Name',
    Actions: 'Actions',
    Add: 'Add',
    Confirm: 'Confirm',
    Search: 'Search',
    All: 'All',
    Action: 'Action',
    Show: 'Show',
    Edit: 'Edit',
    Summary: 'Summary',
    AddOrder: 'Add Order',
    AddStore: 'Add Store',
    Store: 'Store',
    Type: 'Type',
    Status: 'Status',
    Price: 'Price',
    Tel: 'Tel',
    ContactTel: 'Contact Tel',
    Email: 'Email',
    ContactEmail: 'Contact Email',
    Remark: 'Remark',
    Product: 'Product',
    Delete: 'Delete',
    AddProduct: 'Add Product',
    Create: 'Create',
    Update: 'Update',
    Cancel: 'Cancel',
    Date: 'Date',
    Save: 'Save',
    Submit: 'Submit',
    Reject: 'Reject',
    Approve: 'Approve',
    Leave: 'Leave',
    UpdatedAt: 'Updated At',
    Download: 'Download',
    User: 'User',
    Total: 'Total'
  },
  StoreProduct: {
    Store: 'Store',
    ProductName: 'Product Name',
    Price: 'Price',
    Cost: 'Cost',
    Name: 'Name',
    ShortName: 'Short Name',
    Actions: 'Actions',
    Edit: 'Edit',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Back: 'Back',
    AddStoreProduct: 'Add Store Product',
    Combo: 'Combo',
    Recorddeleted: 'Record deleted',
    ContactName: 'Contact Name',
    Tel: 'Tel',
    Email: 'Email',
    Address: 'Address',
    Remark: 'Remark',
    RequiredNameMessage: 'Name is required',
    IncorrectEmailMessage: 'Please input correct email address',
    UpdateFailedMessage: 'Update failed, please retry.',
    Add: 'Add',
    StoreProductupdated: 'Store Product updated',
    StoreProductcreated: 'Store Product created'
  },
  StoreProductCombo: {
    Store: 'Store',
    ProductName: 'Product Name',
    Price: 'Price',
    RawMaterial: 'Raw Material',
    RawMaterialPrice: 'Raw Material Price',
    PercentageOf: 'Percentage',
    PercentagePrice: 'Percentage Price',
    StoreStock: 'Store Stock',
    WholeStock: 'All store stock',
    Name: 'Name',
    ShortName: 'Short Name',
    Actions: 'Actions',
    Edit: 'Edit',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Back: 'Back',
    AddStoreProductCombo: 'Add Store Product Combo',
    Combo: 'Combo',
    Recorddeleted: 'Record deleted',
    ContactName: 'Contact Name',
    Tel: 'Tel',
    Email: 'Email',
    Address: 'Address',
    Remark: 'Remark',
    RequiredRawMaterialMessage: 'Raw Material is required',
    IncorrectEmailMessage: 'Please input correct email address',
    UpdateFailedMessage: 'Update failed, please retry.',
    Add: 'Add',
    StoreProductComboupdated: 'Store Product Combo updated',
    StoreProductCombocreated: 'Store Product Combo created'
  },
  StoreExpense: {
    Store: 'Store',
    Expense: 'Expense',
    Amount: 'Amount',
    AddStoreExpense: 'Add Store Expense',
    SelectExpense: 'Please select Store Expense',
    Actions: 'Actions',
    Add: 'Add',
    Edit: 'Edit',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Back: 'Back'
  },
  passwords: {
    'throttled': 'Too Many Request, Please wait a while'
  },
  CashExpense: {
    All: 'All Categories',
    Stationary: 'Stationary',
    Food: 'Food',
    Miscellaneous: 'Miscellaneous',
    Laundry: 'Laundry',
    InkBox: 'Ink Box',
    Logistic: 'Logistic',
    Cleaning: 'Cleaning',
    FoodIngredients: 'Food Ingredients',
    Bar: 'Bar'
  }
};
exports.default = _default;